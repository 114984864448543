<script setup lang="ts">
import { MIN_NUMBER_OF_IMPRESSIONS } from '@/config';

const INITIAL_TEXT_HEIGHT = 54;

const CLASSES = {
  COLUMN_RIGHT: 'column-right',
  FIRST_ROW: 'first-row',
  FULL_HEIGHT: 'full-height',
  FULL_WIDTH: 'full-width',
  SECOND_ROW: 'second-row',
};

const { getShopData } = useShopData();

const hasAdditionalInformation = computed(() => (getShopData.value?.quote
  || getShopData.value?.impressions?.length
  || getShopData.value?.description));

const textWithQuotes = computed(() => `"${getShopData.value?.quote}"`);

const hasQuoteAndDescription = computed(() => (getShopData.value?.quote
  && getShopData.value?.description));

const hasShopCover = computed(() => !!getShopData.value?.cover);

const shouldShowImpressions = computed(() => getShopData.value?.impressions
  && getShopData.value?.impressions.length >= MIN_NUMBER_OF_IMPRESSIONS);

const shopInformationBasicWrapperClasses = computed(() => ({
  [CLASSES.FIRST_ROW]: shouldShowImpressions.value,
  [CLASSES.FULL_HEIGHT]: !shouldShowImpressions.value && hasQuoteAndDescription.value,
  'shop-information-basic-wrapper-border-bottom': hasAdditionalInformation.value,
}));

const quoteClasses = computed(() => ({
  [CLASSES.COLUMN_RIGHT]: !shouldShowImpressions.value,
  [CLASSES.FIRST_ROW]: hasQuoteAndDescription.value && shouldShowImpressions.value,
  [CLASSES.FULL_WIDTH]: shouldShowImpressions.value && !hasQuoteAndDescription.value,
  [CLASSES.SECOND_ROW]: shouldShowImpressions.value || !hasQuoteAndDescription.value,
}));

const descriptionClasses = computed(() => ({
  [CLASSES.COLUMN_RIGHT]: !shouldShowImpressions.value,
  [CLASSES.FULL_WIDTH]: shouldShowImpressions.value && !hasQuoteAndDescription.value,
  [CLASSES.SECOND_ROW]: shouldShowImpressions.value || hasQuoteAndDescription.value,
}));
</script>

<template>
  <div
    v-if="getShopData"
    class="shop-information md:z-30"
    :class="{'mt-8 md:mt-0': !hasShopCover}"
  >
    <div class="shop-information-content">
      <div
        class="shop-information-basic-wrapper"
        :class="shopInformationBasicWrapperClasses"
      >
        <ShopInformationBasic :shop="getShopData" />
      </div>
      <span
        v-if="getShopData?.quote"
        class="shop-information-quote text-xs font-semibold"
        :class="quoteClasses"
        v-text="textWithQuotes"
      />
      <ShopImpressions class="shop-information-impressions" />
      <UIReadMoreOrLess
        class="shop-information-description text-xs font-normal"
        :class="descriptionClasses"
        :initial-height="INITIAL_TEXT_HEIGHT"
      >
        <span
          class="text-xs"
          v-text="getShopData.description"
        />
      </UIReadMoreOrLess>
    </div>
  </div>
</template>

<style scoped lang="scss">
$grid-column-gap: 20px;
$shop-information-basic-width: 425px;

.shop-information {
  display: flex;
  padding-bottom: 15px;

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1050px;
    margin: auto;
  }

  &-basic-wrapper {
    padding: 0 15px;

    &-border-bottom {
      padding-bottom: 15px;
      border-bottom: $default-border;
    }
  }

  &-quote {
    margin: 15px 15px 0;
  }

  &-impressions {
    margin-top: 10px;
  }

  &-description {
    margin: 10px 15px 0;
  }
}

@include bp-desktop {
  .shop-information {
    position: relative;
    padding: 20px;
    background-color: var(--white);

    &-content {
      display: grid;
      grid-template-columns: $shop-information-basic-width calc(100% - $shop-information-basic-width - $grid-column-gap);
      gap: 15px $grid-column-gap;
    }

    &-basic-wrapper {
      display: flex;
      align-items: center;
      padding: 0;

      &-border-bottom {
        border-bottom: none;
      }
    }

    &-quote,
    &-description,
    &-impressions {
      margin: 0;
    }
  }

  .column-right {
    grid-column: 2;
  }

  .first-row {
    grid-row: 1;
  }

  .full-height {
    grid-row: 1 / span 2;
  }

  .full-width {
    grid-column: 1 / span 2;
  }

  .second-row {
    grid-row: 2;
  }
}
</style>
