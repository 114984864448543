import { State } from '@/config/state';
import type { Shop } from '@/types/shops';

const useShopData = () => {
  const shopData = useState<Shop>(State.ShopData);

  const setShopData = async (slug: string, getDataCached = true) => {
    shopData.value = (await GqlShop({
      getDataCached,
      slug,
    })).shop;
  };

  const getShopData = computed(() => shopData.value);

  return {
    getShopData,
    setShopData,
  };
};

export { useShopData };
